import {consoleLogSWFlagMap, installNoOperationSWFeatureFlagMap} from '../config';
import {consoleLogClientFlagMap, installSWFeatureFlagMap, unRegisterSWFeatureFlagMap} from './config';

const _location = location || {};

/**
 * Method to check the feature flag to decide whether SW can install or not
 *
 * @returns boolean
 */
export const canInstallSW = () => {
	const host = _location.host;
	return installSWFeatureFlagMap.has(host) && installSWFeatureFlagMap.get(host);
};

/**
 * Method to check the feature flag to decide whether no-operation SW can be installed or not
 *
 * @returns boolean
 */
export const canInstallNoopSW = () => {
	const host = _location.host;
	return installNoOperationSWFeatureFlagMap.has(host) && installNoOperationSWFeatureFlagMap.get(host);
};

/**
 * Method to check the feature flag to decide whether SW can uninstall or not
 *
 * @returns boolean
 */
export const canUnInstallSW = () => {
	const host = _location.host;
	return unRegisterSWFeatureFlagMap.has(host) && unRegisterSWFeatureFlagMap.get(host);
};

/**
 * exporting modified console object to log outputs. This method disables logs based on feature flag
 *
 * Two console objects
 * 	- consoleInClient : handle client side logs
 * 	- consoleInSW : handle sw thread side logs
 */
export const consoleInClient = (function() {
	const host = _location.host;
	return consoleLogClientFlagMap.has(host) ? console : {};
})();

export const consoleInSW = (function() {
	const host = _location.host;
	return consoleLogSWFlagMap.has(host) ? console : {};
})();
