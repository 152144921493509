/* eslint-disable no-undef */
import {featureFlagMap, APPLICATION_HOST} from '../utils';

// =======================================================================================================================

// if SW feature is going to remove from project or disabling in fututre
// Once SW code is removed, need to remove the exisitng sw from window
// unregisterSW=true (in any env) will ungister and clean up the caches registered by old SWs
// And while unregstering sw, need to remove exisiting

const UNREGISTER_SW_IN = {
	LOCAL_HOST: false,
	DEV: false,
	STAGE: false,
	PROD: false,
};

// =======================================================================================================================

/* feature flag - using this we can set which env is allowed to register sw

	if true 
			- SW registration script will run.

	if false 
			- If no sw installed previoursly, new sw instanace will not be registered.
			- If a sw installed previoursly, this flag doesnt remove the old one. But will stop register. 
					-	Old sw will be used until it's removed.
					-	To remove old sw,  pls set unregisterSW=true  in above UNREGISTER_SW_IN config
*/

const ENABLE_SW_IN = {
	LOCAL_HOST: false,
	DEV: true,
	STAGE: true,
	PROD: true,
};

// =======================================================================================================================

// Enable / Disable console log in sw thread
const ENABLE_CLIENT_CONSOLE_LOG_IN = {
	LOCAL_HOST: true,
	DEV: true,
	STAGE: true,
	PROD: false,
};

// =======================================================================================================================

export const installSWFeatureFlagMap = featureFlagMap(APPLICATION_HOST, ENABLE_SW_IN);
export const unRegisterSWFeatureFlagMap = featureFlagMap(APPLICATION_HOST, UNREGISTER_SW_IN);
export const consoleLogClientFlagMap = featureFlagMap(APPLICATION_HOST, ENABLE_CLIENT_CONSOLE_LOG_IN);
