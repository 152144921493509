import {listeners} from './listeners';
import {registerServiceWorker} from './registerSW';
import {unregisterServiceWorker} from './unregisterSW';
import {canInstallSW, canUnInstallSW} from './envCheck';

const installSWInCurrentENV = canInstallSW();
const unInstallSWInCurrentENV = canUnInstallSW();

if (unInstallSWInCurrentENV) {
	unregisterServiceWorker();
} else {
	installSWInCurrentENV && registerServiceWorker(listeners);
}
