export function featureFlagMap(host, flags) {
	const featureFlagMap = new Map();

	Object.keys(flags).forEach(key => {
		featureFlagMap.set(host[key], flags[key]);
	});

	return featureFlagMap;
}

export function reloadAllWindowsOnServiceWorkerUpdate() {
	self.clients
		.matchAll({
			type: 'window',
		})
		.then(windowClients => {
			windowClients.forEach(windowClient => {
				windowClient.navigate(windowClient.url);
			});
		});
}

// list of host that are are elible to deploy the SW
export const APPLICATION_HOST = {
	LOCAL_HOST: 'localhost:9000',
	DEV: 'tcc.tis-dev.trane.com',
	STAGE: 'tcc.tis-staging.trane.com',
	PROD: 'tcc.tis.trane.com',
};
