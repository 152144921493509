/* eslint-disable no-console */

import {Workbox} from 'workbox-window';

export function registerServiceWorker(callback = () => null) {
	if ('serviceWorker' in navigator) {
		const wb = new Workbox('/sw.js');

		// allows to register event listeners
		callback(wb);

		wb
			.register()
			.then(registration => {
				console.log('SW registered : ', registration);
			})
			.catch(registrationError => {
				console.log('SW registration failed : ', registrationError);
			});
	}
}
