/* eslint-disable no-console */
import {consoleInClient} from './envCheck';

const console = consoleInClient;

export function unregisterServiceWorker() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.getRegistrations().then(registrations => {
			if (registrations) {
				console.log('Unregistering SW in this page :', registrations);
				const unregisterPromises = [];

				for (let registration of registrations) {
					console.log('SW in this page :', registration);
					unregisterPromises.push(registration.unregister());
				}

				if (unregisterPromises.length)
					Promise.allSettled(unregisterPromises)
						.then(() => {
							_clearCacheWhenUnregesteringSW().then(() => {
								console.log('Removed SW in this page and reloading this page');
								_reloadAfterSomeSeconds();
							});
						})
						.catch(unregistrationError => {
							console.log('Unregistering SW is failed due to :', unregistrationError);
						});
			} else {
				console.log('No SW installed in this page. Unable to perform unregistering SW action');
			}
		});
	}
}

function _clearCacheWhenUnregesteringSW() {
	return caches.keys().then(cacheNames => {
		return Promise.allSettled(cacheNames.map(cacheName => caches.delete(cacheName)));
	});
}

function _reloadAfterSomeSeconds(time = 3000) {
	setTimeout(() => {
		window.location && location.reload(true);
	}, time);
}
