/* eslint-disable no-undef */
import {featureFlagMap, APPLICATION_HOST} from './utils';

export const CACHE_EXPIRE = {
	IN_1_MIN: 60,
	IN_10_MINS: 10 * 60,
	IN_15_MINS: 15 * 60,
	IN_55_MINS: 55 * 60,
	IN_1_HOUR: 60 * 60,
	IN_3_HOURS: 3 * 60 * 60,
	IN_4_HOURS: 4 * 60 * 60,
	IN_1_DAY: 1 * 24 * 60 * 60,
	IN_3_DAYS: 3 * 24 * 60 * 60,
	IN_7_DAYS: 7 * 24 * 60 * 60, // day * hours * mins * secs
};

export const REQUEST_MATCH_HEADERS = {
	CACHE_NAME: 'Client-Sw-Req-Cache-Name',
	CACHE_KEY: 'Client-Sw-Req-Cache-Key',
	CACHE_EXPIRE: 'Client-Sw-Req-Cache-Exp',
	CACHE_ALLOW: 'Client-Sw-Req-Cache-Allow',
};

// =======================================================================================================================

// Need to update SW version whenever there is a change in libs or files under sw foler in order to track sw related changes.
export const SW_VERSION = 'v0.0.1'; //

// Need to update Cache version if new need to delete existing caches.
export const CACHE_VERSION = 'v0.0.1';

// =======================================================================================================================

export const ROUTE_CACHE_NAMES = {
	SCRIPT: 'cc-scripts-' + CACHE_VERSION,
	// Json data should be updated on each release, so updating cache name on every release.
	JSON: 'cc-json-' + CACHE_VERSION,
	STYLE: 'cc-styles-' + CACHE_VERSION,
	FONT: 'cc-fonts-' + CACHE_VERSION,
	IMAGE: 'cc-images-' + CACHE_VERSION,
	API_APP: 'cc-app-api-' + CACHE_VERSION,
	API_USER_SESSION: 'cc-session-api-' + CACHE_VERSION,
};

// =======================================================================================================================

// if any buggy sw was installed, replace the buggy with no-op sw which doesnt perform any cache operations.
// App will work like without SW.

const ENABLE_NO_OPERATION_SW_IN = {
	LOCAL_HOST: false,
	DEV: false,
	STAGE: false,
	PROD: false,
};

// =======================================================================================================================

// Enable / Disable console log in sw thread
const ENABLE_SW_CONSOLE_LOG_IN = {
	LOCAL_HOST: false,
	DEV: true,
	STAGE: true,
	PROD: false,
};

// =======================================================================================================================

export const installNoOperationSWFeatureFlagMap = featureFlagMap(APPLICATION_HOST, ENABLE_NO_OPERATION_SW_IN);
export const consoleLogSWFlagMap = featureFlagMap(APPLICATION_HOST, ENABLE_SW_CONSOLE_LOG_IN);
