/* eslint-disable no-console */

import {cleanupOutdatedCachesByUrl} from './cleanOudatedCaches';
import {consoleInClient} from './envCheck';

const _window = window;
const console = consoleInClient;

export function listeners(wb) {
	wb.addEventListener('installed', event => {
		// `event.isUpdate` will be true if another version of the service
		// worker was controlling the page when this version was installed.
		if (event.isUpdate) {
			console.log('Service worker is installing the updates.');
		} else {
			console.log('Service worker is installing for the first time!.');
		}
	});

	wb.addEventListener('waiting', event => {
		// Assuming the user accepted the update, set up a listener
		// that will reload the page as soon as the previously waiting
		// service worker has taken control.
		wb.addEventListener('controlling', () => {
			// At this point, reloading will ensure that the current
			// tab is loaded under the control of the new service worker.
			// Depending on your web app, you may want to auto-save or
			// persist transient state before triggering the reload.
			window.location.reload();
		});

		if (event.isUpdate) {
			console.log(' Service worker is waiting to replace existing service worker.', event.wasWaitingBeforeRegister);
		} else {
			console.log(' Service worker is waiting for the first time to install it.');
		}

		// When `event.wasWaitingBeforeRegister` is true, a previously
		// updated service worker is still waiting.
		if (event.wasWaitingBeforeRegister) {
			// pass message to sw to trigger self.skip_Waiting()
			wb.messageSkipWaiting();
			return;
		}

		// _window.alert('Application will reload to update. Please wait.');
		// pass message to sw to trigger self.skip_Waiting()
		wb.messageSkipWaiting();
	});

	wb.addEventListener('activated', event => {
		// `event.isUpdate` will be true if another version of the service
		// worker was controlling the page when this version was registered.

		// If your service worker is configured to precache assets, those
		// assets should all be available now.
		if (event.isUpdate) {
			console.log('Service worker was activated after the update!.');
			// On update only - pass message sw to reload all tabs that opened the application
			wb.messageSW({type: 'RELOAD_ALL_CLIENT_TABS_ON_SW_UPDATE'});
		} else {
			console.log('Service worker was activated for the first time!.');
		}

		// Clean existing outdated cached url that contains old app version
		// eslint-disable-next-line no-undef
		// cleanupOutdatedCachesByUrl(process.env.APP_CURRENT_VERSION).then(() => {
		// 	if (event.isUpdate) {
		// 		// On update only - pass message sw to reload all tabs that opened the application
		// 		wb.messageSW({type: 'RELOAD_ALL_CLIENT_TABS_ON_SW_UPDATE'});
		// 	}
		// });
	});

	// Save SW version in window for developer purpose
	wb.messageSW({type: 'GET_VERSION'}).then(swVersion => {
		console.log('Current Service Worker version :', swVersion);
		_window['___APP_SW_VERSION'] = swVersion;
	});
}
